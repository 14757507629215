import { acceptHMRUpdate, defineStore } from "pinia";

export const useUi = defineStore("ui", {
  state: () => {
    return {
      slowInternet: null,
      errorModal: false,
      debugMode: false,
      showRegionModal: false,
    };
  },
  getters: {},
  actions: {
    openError() {
      this.errorModal = true;
      console.log('Error');
    },
    toggleRegionModal(value) {
      this.showRegionModal = value !== undefined ? value : !this.showRegionModal;
    },
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useUi, import.meta.hot));
}
